<template>
    <a-modal :visible="visible" :title="type == 'income' ? '收入分配 ' : '支出分配 ' +amount+'(万元)'" :footer="null" @cancel="cancel">
        <span v-if="type=='income'">确认阶段</span>
        <a-table v-if="type=='income'" bordered :dataSource="filteredListA" :pagination="false">
            <a-table-column title="阶段" width="120px" align="center">
                <template slot-scope="text">
                    {{ text.phaseName }}
                </template>
            </a-table-column>
            <a-table-column title="阶段占比" align="right">
                <template slot-scope="text,row,index">
                    <span v-if="editable">
                    <a-input style="width: 60px;" type="number" v-model="text.prop" size="small"
                        @input="caclAmount(0,index)" />%</span>
                    <span v-else>{{text.prop}}%</span>    
                </template>
            </a-table-column>
            <a-table-column title="阶段金额(万元)" align="right">
                <template slot-scope="text">
                    {{ text.amount }}
                </template>
            </a-table-column>
            <!-- <a-table-column title="已收入/阶段总金额(万元)">
                <template slot-scope="text">
                    {{ text.amount }}
                </template>
            </a-table-column> -->
        </a-table>
        <a-checkbox v-if="type=='expend' && editable==true" v-model="ifSync">根据收入比例，系统自动同步</a-checkbox>
        <div style="margin-top: 10px;">部门比例</div>
        <a-table bordered :dataSource="filteredListB" :pagination="false">
            <a-table-column title="部门" width="120px" align="center">
                <template slot-scope="text">
                    <a-select v-if="editable" placeholder="生产部门" show-search :filter-option="$selectFilterOption"
                        v-model="text.deptId" style="width: 150px">
                        <a-select-option v-for="item in productionDepartmentList" :key="item.id" :value="item.id">
                            {{ item.uniqueName}}
                        </a-select-option>
                    </a-select>
                    <span v-else>
                        {{ text.deptName }}
                    </span>
                </template>
            </a-table-column>
            <a-table-column title="部门占比" align="right">
                <template slot-scope="text,row,index">
                    <span v-if="editable"><a-input style="width: 60px;" type="number" v-model="text.prop"
                            size="small" @input="caclAmount(1,index)" />%</span>
                    <span v-else>
                        {{ text.prop }}%
                    </span>

                </template>
            </a-table-column>
            <a-table-column title="分配金额" align="right">
                <template slot-scope="text">
                    {{ text.amount }}
                </template>
            </a-table-column>
            <a-table-column v-if="editable==true" align="center" title="操作" width="120px">
                <template slot-scope="text, row, index">
                    <div>
                        <a-space>
                            <!-- <a href="javascript:void(0)" v-if="text.editable" @click="handleSave(text, index)">保存</a> -->
                            <a href="javascript:void(0)" @click="handleDelete(text, index)">删除</a>
                        </a-space>
                    </div>
                </template>
            </a-table-column>
        </a-table>
        <div style="display: flex;margin-top: 10px;align-items: center;">
            <a-button v-if="editable==true" type="primary" style="margin-bottom: 5px;margin-right: 10px;" @click="handleAdd">
                增加部门
            </a-button>
            <a-button v-if="type=='income' && editable==true" style="margin-bottom: 5px;margin-right: 5px;" @click="autoCalc">
                自动计算
            </a-button>
            <a-tooltip v-if="type=='income' && editable==true">
                <template slot="title"> 根据本项收入的各阶段比例*收入金额*各部门产值分配比例，自动计算各部门对这笔金额分配占比 </template>
                <a-icon type="info-circle" style="font-size: 18px" />
            </a-tooltip>
        </div>
        <div style="text-align: center;margin-top: 20px;">
            <a-space>
                <a-button @click="cancel">取消</a-button>
                <a-button type="primary" v-if="editable==false" @click="editable=true">修改分配</a-button>
                <a-button type="primary" v-if="editable==true" @click="handleSubmit">保存</a-button>
            </a-space>
        </div>
    </a-modal>
</template>

<script>
import organization from "@/mixins/organization";
import request from '@/api/request'
function save(data) {
  return request({
      url:  '/project-service/projectFin/allocate/save',
      method: 'post',
      data,
  })
}
function calc(data) {
  return request({
      url:  '/project-service/projectFin/calc',
      method: 'post',
      data,
  })
}

export default {
    mixins: [ organization],
    props: {
        amount:{
            type: Number,
            default: 0,
        },
        type:{
            type: String,
            default: '',
        },
        finId:{
            type: String,
            default: '',
        },
        visible: {
            type: Boolean,
            default: false
        },
        ifSync: {
            type: Boolean,
            default: false
        },
        allocateOver:{
            type: Number,
            default: 0,
        },
        filteredList1: [],
        filteredList2: [],
    },
    data() {
        return {
            editable: false,
            show: false,
            filteredListA: [],
            filteredListB: []
        };
    },

    watch: {
        filteredList1(newValue) {
            this.filteredListA = JSON.parse(JSON.stringify(newValue))
        },
        filteredList2(newValue) {
            this.filteredListB = JSON.parse(JSON.stringify(newValue))
        },
        visible(newValue) {
            if(newValue){
                this.editable = false;
                if(!this.allocateOver){
                    this.editable = true;
                }
            }
        }
    },

    methods: {
        autoCalc() {
            const { id } = this.$route.query;
            calc({
                progressList: this.filteredListA.map(v=>{
                    return {
                        phaseCode: v.phaseCode,
                        phaseName: v.phaseName,
                        progressProp: v.prop
                    }
                }),
                projectId:id
            }).then((res)=>{
                this.filteredListB = res.map(v=>{
                    return {
                        editable: true,
                        amount: v.prop * this.amount/100,
                        deptId: v.deptId,
                        deptName: v.deptName,
                        prop: v.prop
                    }
                })
            })
        },
        caclAmount(type, index){
            if(type == 0){
                const a = this.filteredListA[index].prop * this.amount/100;
                let c = this.filteredListA[index];
                c.amount = a;
                this.filteredListA.splice(index, 1, c);
            }
            if(type == 1){
                const a = this.filteredListB[index].prop * this.amount/100;
                let c = this.filteredListB[index];
                c.amount = a;
                this.filteredListB.splice(index, 1, c);
            }
        },
        handleAdd() {
            const newData = {
                editable: true,
                deptName: null,
                prop: null,
                amount: null
            };
            this.filteredListB.push(newData)
        },
        handleSave(text, index) {
            this.filteredListB.splice(index, 1, {
                deptName: text.deptName,
                prop: text.prop,
                amount: text.amount,
                editable: false
            })
        },
        handleDelete(text, index) {
            this.filteredListB.splice(index, 1)
        },
        handleSubmit() {
            if(this.filteredListB.length<1){
                this.$message.error("请增加部门");
                return;
            }
            let json = {
                id: this.finId,
                type: this.type,
                deptList: this.filteredListB.map(v=>{
                    return {
                        amount: v.amount,
                        finId: this.finId,
                        deptId: v.deptId,
                        deptName: this.productionDepartmentList.filter(item=>item.id == v.deptId)[0].uniqueName,
                        prop: v.prop
                    }
                }),
                progressList: this.filteredListA.map(v=>{
                    return {
                        amount: v.amount,
                        finId: this.finId,
                        phaseCode: v.phaseCode,
                        phaseName: v.phaseName,
                        prop: v.prop
                    }
                })
            }
            if(this.type=='expend'){
                json.ifSync = this.ifSync?1:0;
            }
            console.log(json)
            save(json).then(()=>{
                console.log(111)
                this.$emit("success");
            })
        },
        cancel() {
            this.$emit("setVisible", false);
        }

    }
};
</script>