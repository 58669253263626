<template>
  <div>
    <Pane />
    <Allocate :visible="allocateVisible" :ifSync="ifSync" :allocateOver="allocateOver" :amount="amount" :finId="finId" :type="type" :filteredList1="progressList" :filteredList2="allocateList" @setVisible="allocateVisible=false" @success="saveSuccess"/>
    <a-modal width="800px" :visible="visible" :title="type == 'income' ? '新增收入' : '新增支出'" :footer="null" @cancel="visible = false">
      <a-button style="margin-bottom: 5px;" @click="handleAdd">
        新增
      </a-button>
      <a-table bordered :dataSource="filteredList" :pagination="false">
        <a-table-column title="日期" width="120px" align="center">
          <template slot-scope="text">
            <a-date-picker format="YYYY-MM-DD" v-if="text.editable" v-model="text.date" size="small" />
            <span v-else>
              {{ text.date }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="归属月份" width="120px" align="center">
          <template slot-scope="text">
            <a-date-picker format="YYYY-MM" v-if="text.editable" v-model="text.belongMonth" size="small" />
            <span v-else>
              {{ text.belongMonth }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="金额(万)" width="100px" align="center">
          <template slot-scope="text">
            <a-input type="number" v-if="text.editable" v-model="text.amount" size="small" />
            <span v-else>
              {{ text.amount }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="分包方" align="center" v-if="type !== 'income'">
          <template slot-scope="text, row, index">
            <CompanySelector @change="(company) => selectedCompany(company, index)">
            <a-button block style="text-align: left" class="ellipsis">
              <div
                class="left text-hidden selected"
                v-if="text.companyName"
              >
                {{ text.companyName }}
              </div>
              <div class="left placeholder" v-else>请选择公司</div>
            </a-button>
          </CompanySelector>
          </template>
        </a-table-column>
        <a-table-column align="center" title="操作" width="120px">
          <template slot-scope="text, row, index">
            <div>
              <a-space>
                <a href="javascript:void(0)" @click="handleDelete(text, index)">删除</a>
              </a-space>
            </div>
          </template>
        </a-table-column>
      </a-table>
      <div style="text-align: center;margin-top: 20px;">
        <a-space>
          <a-button @click="visible = false">取消</a-button>
          <a-button type="primary" @click="handleSubmit">保存</a-button>
        </a-space>
      </div>
    </a-modal>
    <a-card class="container">

      <div class="card-header">
        <div class="card-title">收支概况</div>
      </div>

      <a-descriptions bordered :column="2" size='small'>
        <a-descriptions-item>
          <div slot="label" class="center">项目名称</div>
          <div>
            {{ detail.name }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">设计编号</div>
          <div>
            {{ detail.designCode }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">合同金额(元)</div>
          <div style="color: #1890ff">
            <Money :money="detail.projectAmount" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">分包总额(万)</div>
          <div style="color: #1890ff">
            <Money :money="detail.subSum" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">合同净额(万)</div>
          <div style="color: #1890ff">
            <Money :money="detail.projectPureAmount" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">生产负责人</div>
          <div>
            {{ detail.chargerName }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">设计类必要分包(万)</div>
          <div style="color: #1890ff">
            <Money :money="detail.subNeedSum" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">总承包类分包(万)</div>
          <div style="color: #1890ff">
            <Money :money="detail.subZcbSum" />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">实际收入进度</div>
          <div>
            {{ (detail.receivedAmount && detail.projectAmount) ? (detail.receivedAmount / detail.projectAmount).toFixed(6) * 100 : 0 }}%
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">实际支出进度</div>
          <div>
            {{ (detail.expendSum && detail.subSum) ? (detail.expendSum / detail.subSum).toFixed(6) * 100 : 0}}%
          </div>
        </a-descriptions-item>
      </a-descriptions>


      <div class="card-header">
        <div class="card-title">明细</div>
        <div>
          <a-button type='primary' @click="addIncome" style="margin-right: 10px;">新增收入</a-button>
          <a-button type='primary' @click="addExpend">新增实际支出</a-button>
        </div>
      </div>
      <a-table bordered :data-source="finList" :loading="loading" :pagination="false">
        <a-table-column title="日期" data-index='date' />
        <a-table-column title="归属月份" data-index='belongMonth' />
        <a-table-column title="确认阶段">
          <template slot-scope="text">
            <span v-for="(item, index) in text.progressList" :key="index">{{ item.phaseName }}({{ item.prop }}%)、</span>
          </template>
        </a-table-column>
        <a-table-column title="收入(万)" width="120px" align='right'>
          <template slot-scope="text,row">
            <span v-if="text.type == 'income'">
              <span v-if="text.allocateOver">{{ text.amount }}
                <a style="color: #1890ff" href="#" @click.prevent="allocate(row)">
                  <a-icon type="info-circle" style="font-size: 18px;color: #1890ff;" />
                </a>
              </span>
              <span v-else style="color: #1890ff">
                {{ text.amount }}
                <a style="color: #1890ff" href="#" @click.prevent="allocate(row)">
                  (未分配)
                  <span class="iconfont icon-fenpeijiaose" style="color: #1890ff"></span>
                </a>
              </span>
            </span>
            <span v-else>-</span>
          </template>
        </a-table-column>
        <a-table-column title="累计收入(万)" align='right'>
          <template slot-scope="text, row, index">
            {{ leiJiAmount(index) }}
          </template>
        </a-table-column>
        <a-table-column title="预计支出(万)" align='right'>
          <template slot-scope="text">
            {{ (detail.subSum / detail.projectAmount * text.amount).toFixed(6) }}
          </template>
        </a-table-column>
        <a-table-column title="累计预计支出(万)" align='right'>
          <template slot-scope="text, row, index">
            {{ leiJiYuJiAmount(index) }}
          </template>
        </a-table-column>
        <a-table-column title="实际支出(万)" align='right'>
          <template slot-scope="text, row">
            <span v-if="text.type == 'expend'">
              <span v-if="text.allocateOver">{{ text.amount }}
                <a style="color: #1890ff" href="#" @click.prevent="allocate(row)">
                  <a-icon type="info-circle" style="font-size: 18px;color: #1890ff;" />
                </a>
              </span>
              <span v-else style="color: #1890ff">
                {{ text.amount }}
                <a style="color: #1890ff" href="#" @click.prevent="allocate(row)">
                  (未分配)
                  <span class="iconfont icon-fenpeijiaose" style="color: #1890ff"></span>
                </a>
              </span>
            </span>
          </template>
        </a-table-column>
        <a-table-column title="分包单位" data-index='companyName' />
        <a-table-column title="累计实际支出(万)" align='right'>
          <template slot-scope="text, row, index">
            {{ leiJiShiJiAmount(index).toFixed(6) }}
          </template>
        </a-table-column>
        <a-table-column title="支出预算余额(万)" align='right'>
          <template slot-scope="text, row, index">
            {{ (leiJiYuJiAmount(index) - leiJiShiJiAmount(index)).toFixed(6)}}
          </template>
        </a-table-column>
        <a-table-column title="净收入余额(万)" align='right'>
          <template slot-scope="text, row, index">
            {{ (leiJiAmount(index) - leiJiYuJiAmount(index)).toFixed(6) }}
          </template>
        </a-table-column>
        <a-table-column title="操作" width='120px' align='right'>
          <template slot-scope="text, row">
            <a href="javascript:void(0)" class="danger" @click="removeA(row)">删除</a>
          </template>
        </a-table-column>
      </a-table>

      <div class="card-header">
        <div class="card-title">分配详情</div>
      </div>
      <div class="sub-header">
        部门收入汇总
      </div>
      <a-table bordered :data-source="incomeDeptSum" :loading="loading" :pagination="false">
        <a-table-column title="部门" data-index='deptName' />
        <a-table-column title="收入部门占比">
          <template slot-scope="text">
            {{ text.prop }}%
          </template>
        </a-table-column>
        <a-table-column title="收入金额(万元)" data-index='amount' />
      </a-table>
      <div class="sub-header">
        部门支出汇总
      </div>
      <a-table bordered :data-source="expendDeptSum" :loading="loading" :pagination="false">
        <a-table-column title="部门" data-index='deptName' />
        <!-- <a-table-column title="预计支出比例">
            <template slot-scope="text">
              {{ text.prop }}%
            </template>
          </a-table-column> -->
        <!-- <a-table-column title="预计支出金额(万元)">
            <template slot-scope="text">
              {{ (detail.subSum / detail.projectAmount * text.amount).toFixed(6) }}
            </template>
          </a-table-column> -->
        <a-table-column title="实际支出比例">
          <template slot-scope="text">
            {{ text.prop }}%
          </template>
        </a-table-column>
        <a-table-column title="实际支出金额(万元)" data-index='amount' />
      </a-table>
      <div class="center" style="margin-top: 40px;">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>



<script>

import request from '@/api/request'
import Allocate from './components/allocate.vue'
import CompanySelector from "@/components/company-selector";
function fetchDetail(id) {
  return request({
      url:  '/project-service/projectFin/detail/' +id,
      method: 'post',
  })
}
function add(data) {
  return request({
      url:  '/project-service/projectFin/add',
      method: 'post',
      data,
  })
}

function remove(id) {
  return request({
      url:  '/project-service/projectFin/del/' +id,
      method: 'post',
  })
}

export default {

  name: 'projectFin_detail',
  components:{
    Allocate,
    CompanySelector
  },
  data() {
      return {
          allocateVisible: false,
          visible: false,
          loading: false,
          detail: {},
          bidList: [],
          bidBudgetList: [],

          saveLoading: false,
          finList: [],
          incomeDeptSum: [],
          expendDeptSum: [],
          type: '',
          filteredList: [],
          allProgressList: [],
          progressList: [],
          allocateList: [],
          finId: '',
          amount: 0,
          allocateOver: 0,
          ifSync: false
      }
  },

  computed: {
    
  },

  mounted() {
      this.getList();
  },


  methods: {
    selectedCompany(company, index){
      const obj = this.filteredList[index];
      obj.companyId = company.id;
      obj.companyName = company.name;
      this.filteredList.splice(index, 1,obj)
    },
    getList(){
      const { id } = this.$route.query;
      this.loading = true;
      fetchDetail(id).then(res => {
        if (res) {
          this.detail = res;
          this.finList = res.finList || []
          this.incomeDeptSum = res.incomeDeptSum || []
          this.expendDeptSum = res.expendDeptSum || []
          this.allProgressList = res.projectProgressList;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    leiJiAmount(i) {
      let amount = 0;
      this.finList.forEach((item, index) => {
        if(index <= i && item.type == 'income') {
          amount += item.amount;
        }
      });
      return amount
    },
    leiJiYuJiAmount(i) {
      let amount = 0;
      this.finList.forEach((item, index) => {
        if(index <= i) {
          amount += (this.detail.subSum / this.detail.projectAmount * item.amount);
        }
      });
      return amount.toFixed(6)
    },
    leiJiShiJiAmount(i) {
      let amount = 0;
      this.finList.forEach((item, index) => {
        if(index <= i && item.type == 'expend') {
          amount += item.amount;
        }
      });
      return amount
    },
    addIncome() {
      this.visible = true;
      this.type = 'income';
      this.filteredList = [];
    },
    addExpend() {
      this.visible = true;
      this.type = 'expend';
      this.filteredList = [];
    },
    handleAdd() {
        const newData = {
          editable: true,
          belongMonth: null,
          date: null,
          amount: null
        };
        this.filteredList.push(newData)
      },
    handleSave(text, index) {
      this.filteredList.splice(index, 1, {
        belongMonth: text.belongMonth ? text.belongMonth.format("YYYY-MM") : null,
        date: text.date ? text.date.format("YYYY-MM-DD") : null,
        amount: text.amount,
        editable: false
      })
    },  
    handleDelete(text,index) {
      this.filteredList.splice(index, 1)
    },
    handleSubmit() {
      const { id } = this.$route.query;
      this.filteredList = this.filteredList.map(v=>{
        return {
          ...v,
          type: this.type,
          projectId: id,
          belongMonth: v.belongMonth ? v.belongMonth.format("YYYY-MM") : null,
          date: v.date ? v.date.format("YYYY-MM-DD") : null,
        }
      })
      add(this.filteredList).then(()=>{
        console.log(111)
        this.visible = false;
        this.getList();
      })
    },
    allocate(row){
      this.allocateVisible = true;
      this.type = row.type;
      this.finId = row.id;
      this.amount = row.amount;
      this.allocateOver = row.allocateOver;
      this.allocateList = JSON.parse(JSON.stringify(row.deptList));
      this.ifSync= row.ifSync ? true:false;
      if(row.progressList.length>0){
        this.progressList = JSON.parse(JSON.stringify(row.progressList));
      }else{
        this.progressList = JSON.parse(JSON.stringify(this.allProgressList));
      }
    },
    removeA(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove(text.id).then(() => {
            that.getList();
          });
        }
      });
    },
    saveSuccess() {
      this.allocateVisible = false;
      this.getList();
    }
  }
}
</script>

<style lang="less" scoped>
.container {
background-color: #fff;
padding: 24px 160px;
}

.card-header {
margin-top: 16px;
margin-bottom: 8px;
display: flex;
align-items: center;
justify-content: space-between;

.card-title {
font-weight: bold;
font-size: 14px;
color: #1890ff;
}


}
.sub-header{
  margin: 20px 5px 5px 5px;
}
</style>